import { isValidHttpUrl } from '..'
import { DOPPEL_KEYWORD_MATCH } from '@/utils/image'

type UrlData = {
  image_url?: string | undefined
  backup_image_url?: string | undefined
  source_url?: string | undefined
}

export function extractCollectionURIFromMatch(match, isCounterfeit) {
  const collectionSlug = isCounterfeit
    ? match.flagged_nft?.collection_slug
    : match.asset?.nft?.collection_slug
  return 'opensea.io/collection/' + collectionSlug
}

export function extractNftTextData(flagged_nft) {
  if (!flagged_nft) return []
  return [
    flagged_nft.collection_slug,
    flagged_nft.collection_name,
    flagged_nft.title,
    flagged_nft.description,
  ] // TODO add nft description and collection description here
}

export function getReportPreviewImage(report) {
  return extractMediaSrcFromReport(report, 0, true).image_url
}

function extractUrlDataForFlaggedCollection(
  flaggedCollection: any,
  flaggedContentType: AssetType | undefined,
  assetType: AssetType | undefined,
): UrlData {
  if (
    flaggedContentType == AssetType.CollectionProfile ||
    (flaggedCollection.featured_image_url && assetType == AssetType.CollectionProfile)
  ) {
    return {
      image_url:
        flaggedCollection.featured_image_url ||
        flaggedCollection.internal_featured_image_uri,
      backup_image_url: flaggedCollection.internal_featured_image_uri,
      source_url: `https://opensea.io/collection/${flaggedCollection.collection_slug}`,
    }
  }
  if (
    flaggedContentType == AssetType.CollectionBanner ||
    (flaggedCollection.banner_image_url && assetType == AssetType.CollectionBanner)
  ) {
    return {
      image_url:
        flaggedCollection.banner_image_url ||
        flaggedCollection.internal_banner_image_uri,
      backup_image_url: flaggedCollection.internal_banner_image_uri,
      source_url: `https://opensea.io/collection/${flaggedCollection.collection_slug}`,
    }
  }
  return {
    image_url: flaggedCollection.image_url || flaggedCollection.internal_image_uri,
    backup_image_url: flaggedCollection.internal_image_uri,
    source_url: `https://opensea.io/collection/${flaggedCollection.collection_slug}`,
  }
}

export function extractMediaSrcFromReport(selectedReport, index, isCounterfeit) {
  if (!selectedReport) {
    return {
      image_url: '',
    }
  }
  if (index < selectedReport.collection_reports_to_matches.length) {
    const match = selectedReport.collection_reports_to_matches[index].match
    const flaggedContentType = match?.flagged_content_type
    const flaggedNft = match?.flagged_nft
    const asset = match?.asset
    const assetType = match?.asset?.type
    if (isCounterfeit) {
      if (flaggedNft) {
        let primaryUrl = flaggedNft.alternate_media_uri
        if (primaryUrl?.includes('openseauserdata')) {
          primaryUrl = flaggedNft.internal_uri
        }
        const backupImageUrl = flaggedNft.internal_uri
        return {
          image_url: primaryUrl,
          backup_image_url: backupImageUrl,
          source_url: flaggedNft.marketplace_link,
        }
      }
      if (selectedReport.flagged_collection) {
        return extractUrlDataForFlaggedCollection(
          selectedReport.flagged_collection,
          flaggedContentType,
          assetType,
        )
      }
      // TODO: handle flagged_pre_collection
      return {
        image_url: '',
        backup_image_url: '',
        source_url: '',
      }
    } else {
      let primaryURL = asset?.media_uri ? asset?.media_uri : asset?.collection.image_url
      if (asset?.nft) {
        primaryURL = asset.nft.alternate_media_uri
          ? asset.nft.alternate_media_uri
          : asset.nft.media_uri
        if (primaryURL?.includes('openseauserdata')) {
          primaryURL = asset?.nft?.internal_uri
        }
      }
      const backupURL = asset?.nft?.internal_uri

      return {
        image_url: primaryURL ? primaryURL : DOPPEL_KEYWORD_MATCH,
        backup_image_url: backupURL,
        source_url: isValidHttpUrl(asset?.source) ? asset?.source : null,
      }
    }
  }
  return {
    image_url: '',
  }
}

export function isKeywordAsset(asset) {
  return asset?.type === 'keywords'
}

/**
 * @deprecated
 * @param collectionReportsToMatches
 * @returns
 */
export function getMatchTypes(collectionReportsToMatches): string[] {
  const types = new Set<string>()
  collectionReportsToMatches.forEach((reportToMatch) => {
    if (reportToMatch.match?.asset?.type) {
      types.add(
        ASSET_TYPE_TO_MATCHED_REASONS[reportToMatch.match.asset.type] ||
          reportToMatch.match.asset.type,
      )
    }
    if (reportToMatch.match?.image_score) {
      types.add('Image')
    }
    if (reportToMatch.match?.logo_score) {
      types.add('IP')
    }
    if (reportToMatch.match?.keywords_score) {
      types.add('Keyword')
    }
  })
  return Array.from(types)
}

export function extractCollectionSlugFromNftOrCollection(nftOrCollection) {
  const slug = nftOrCollection?.collection_slug ? nftOrCollection?.collection_slug : ''
  return slug?.replace(':brand', '')
}

export const enum MatchType {
  Image = 'Image',
  Keyword = 'Keyword',
  Logo = 'IP',
}

export const ASSET_TYPE_TO_MATCH_TYPE = {
  nft: MatchType.Image,
  keywords: MatchType.Keyword,
  collection_banner: MatchType.Image,
  collection_profile: MatchType.Image,
  collection_image: MatchType.Image,
  logo: MatchType.Logo,
  customer_upload: MatchType.Image,
}

export const ASSET_TYPE_TO_MATCHED_REASONS = {
  nft: 'Image',
  keywords: 'Keyword',
  collection_banner: 'Image',
  collection_profile: 'Image',
  collection_image: 'Image',
  logo: 'IP',
  customer_upload: 'Image',
}

export enum AssetType {
  NFT = 'nft',
  CollectionProfile = 'collection_profile',
  CollectionBanner = 'collection_banner',
  Keywords = 'keywords',
  CustomerUpload = 'customer_upload',
  CollectionImage = 'collection_image',
  Logo = 'logo',
}

export function extractChainFromNftOrCollection(nftOrCollection) {
  return nftOrCollection?.chain ? nftOrCollection?.chain : ''
}
