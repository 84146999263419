import { Select } from '@chakra-ui/react'
import { DOPPEL_TEXT_GREY } from '@/utils/style'

const DropdownSelector = ({
  items,
  selectedItem,
  onItemSelected,
  idField = null,
  nameField = null,
  placeholder = 'None',
  width = '150px',
  ...props
}) => {
  return (
    <Select
      _placeholder={{ textColor: DOPPEL_TEXT_GREY, opacity: 0.1 }}
      fontSize={13}
      onChange={(e) => onItemSelected(e.target.value)}
      placeholder={placeholder}
      value={selectedItem || ''}
      w={width}
      {...props}
    >
      {items?.map((item, index) => {
        // Determine the key and value using idField or index as fallback
        const key = idField && item[idField] !== undefined ? item[idField] : index
        const value =
          idField && item[idField] !== undefined ? item[idField] : item?.toString()

        // Determine the display text using nameField, item as a string, or a default text
        const displayText = nameField ? item[nameField] : item?.toString()

        return (
          <option key={`dropdown-selector-${key}`} value={value}>
            {displayText}
          </option>
        )
      })}
    </Select>
  )
}
export default DropdownSelector
