import { IconProps, useClipboard, useToast } from '@chakra-ui/react'
import { CheckIcon, CopyIcon } from '@chakra-ui/icons'
import DoppelLink from '../report_detail/doppel_link'

type Props = {
  link: string
  onCopyRef?: React.MutableRefObject<() => void>
  size?: string
} & IconProps

const CopyButton: React.FC<Props> = ({ link, onCopyRef, size, ...rest }: Props) => {
  const { hasCopied, onCopy } = useClipboard(link, { timeout: 2000 })
  const toast = useToast()
  const handleCopy = () => {
    onCopy()
    toast({
      title: 'Link copied',
      description: (
        <DoppelLink lineHeight="1.5" maxW="300px" name={link} noOfLines={2} />
      ),
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }
  if (onCopyRef) onCopyRef.current = handleCopy

  // if ref is used, that means copy click is handled on higher level
  const onIconClick = onCopyRef ? () => {} : handleCopy

  const sharedProps = {
    ml: 2,
    size: size,
    ...rest,
  }
  return hasCopied ? (
    <CheckIcon {...sharedProps} />
  ) : (
    <CopyIcon cursor={'pointer'} onClick={onIconClick} {...sharedProps} />
  )
}

export default CopyButton
