import { IconButton } from '@chakra-ui/react'
import { IoMdImage } from 'react-icons/io'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../../utils/style'
import { Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useGetGcsObjectQuery } from '@/generated/graphql'
import { RETRACTION_SCREENSHOTS_BUCKET } from '@/utils/web2'

// Retraction button that opens modal to start retractions
function RetractionScreenshotViewButton({
  enforcementRequestId,
}: {
  enforcementRequestId: string
}): ReactElement {
  const [isEmployeeView] = useIsEmployeeView()

  const { data, loading, error } = useGetGcsObjectQuery({
    variables: {
      object_name: enforcementRequestId,
      bucket_name: RETRACTION_SCREENSHOTS_BUCKET,
      include_object: false,
    },
  })

  if (!isEmployeeView) return <></>

  return (
    <Tooltip label="View Retraction Screenshot">
      <IconButton
        aria-label="retraction button"
        bgColor={DOPPEL_INTERNAL_PURPLE}
        color={DOPPEL_WHITE}
        icon={<IoMdImage />}
        onClick={() => {
          window.open(
            data?.get_gcs_object?.object_info?.object_versions[0]?.signed_url,
            '_blank',
          )
        }}
        size={'xs'}
      />
    </Tooltip>
  )
}

export default RetractionScreenshotViewButton
